.rct-dateHeader {
    border: none !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #9e9e9e !important;
    z-index: 1001 !important;
}

.rct-dateHeader-primary {
    transform: translateY(-20px) !important;
}

.react-calendar-timeline .rct-header-root {
    background-color: #fff;
    border-bottom: none;
}

.react-calendar-timeline .rct-dateHeader {
    border: none;
}

.react-calendar-timeline .rct-calendar-header {
    border: none;
    margin-bottom: 0;
}
.rct-sidebar-row {
    line-height: 20px !important;
    border: 1px solid #DADADA !important;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6 {
    background-color: rgba(255, 255, 255, 0.5) !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background-color: rgba(255, 255, 255, 0.5) !important;
}

.react-calendar-timeline .rct-dateHeader {
    background-color: #fff;
}

.react-calendar-timeline .rct-dateHeader-primary {
    color: black;
}

.rct-sidebar-row .rct-sidebar-row-even {
    line-height: 0px !important;
}

.rct-sidebar {
    border-right: none !important;
    z-index: 7002;
    background: white;
    border-radius: 8px;
    width: 350px !important;
}

.rct-sidebar > div {
    width: 350px !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background-color: white;
}


.rct-calendar-header {
    margin-bottom: 30px;
    transform: translateX(200px);
}

.react-calendar-timeline .rct-outer {
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: 80vh !important;
}

.rct-outer::-webkit-scrollbar {
    display: none;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px !important; /* Ensure group row height matches timeline cell height */
    font-size: 18px !important; /* Adjust font size for better readability */
    font-weight: bold !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background-color: #fff;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid gray !important;
}
.rct-vl {
    /*width: 30% !important;*/
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    border-bottom: 1px solid gray !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid gray !important;
}

.react-calendar-timeline .rct-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.react-calendar-timeline .rct-scroll::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
}

.rct-item-Azs {
    position: absolute !important;
    width: 100% !important;
    height: 100px !important; /* Ensure item height matches group row height */
    font-size: 14px !important;
    font-weight: 400 !important;
    /*color: #757575 !important;*/
    line-height: 15.68px;
    overflow: hidden !important;
    text-align: left !important;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0 !important;
    top: 0 !important;
}
