@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Source Sans 3';
    src: url('./font/SourceSans3-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Source Sans 3', sans-serif;
}

.bg-custom-gradient {
    background: linear-gradient(88.98deg, rgba(52, 147, 223, 0.7) 0%, rgba(205, 102, 214, 0.7) 50%, rgba(255, 184, 0, 0.7) 100%)
}

.custom-sidebar-shadow {
    box-shadow: 0px 4px 20px 0px #00000014;
}

.rmdp-top-class {
    border: none !important;
}

.rmdp-calendar {
    border: none !important;
}

.end {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.start {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.sd {
    border-radius: 0 !important;
}

.rmdp-wrapper {
    width: 100% !important;
    border: none !important;

    box-shadow: none !important;
}
.rmdp-top-class {
    display: block !important;
    width: 100% !important;
}

.rmdp-day-picker {
    display: block !important;
}

.rmdp-week {
    display: flex !important;
}

.rmdp-range {
    /*background-color: #F62E46 !important;*/
}

.rmdp-week-day {
    width: 50px !important;
    height: 50px !important;
}

.sd {
    /*background-color: #0000000D !important;*/
}

.rmdp-day  {
    width: 15% !important;
    height: 40px !important;
    z-index: 10;

    /*background: #0000000D !important;*/

}


/*.react-datepicker {*/
/*    width: 100%;*/
/*    border: none !important;*/

/*}*/
/*.react-datepicker__month-container {*/
/*    width: 100%;*/
/*    border: none !important;*/
/*}*/

/*.react-datepicker__header  {*/
/*    background-color: white !important;*/
/*    border:none !important;*/
/*}*/

/*.react-datepicker__day-names {*/
/*    width: 100%;*/
/*}*/

/*.react-datepicker__week {*/
/*    display: flex !important;*/
/*    justify-content: center;*/
/*}*/

/*.react-datepicker__day-name {*/
/*    width: 13% !important;*/
/*}*/

/*.react-datepicker__day {*/
/*    width: 13% !important;*/
/*    height: 30px !important;*/

/*    display: flex !important;*/
/*    align-items: center !important;*/
/*    justify-content: center !important;*/

/*    border-radius: 4px !important;*/

/*    background-color: #0000000D;*/
/*}*/


@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.pulse-animation {
    animation: pulse 1s;
}

.always-down .dropdown-container {
    position: relative;
    overflow: visible !important; /* Убирает внутренний скролл */
}

.always-down .dropdown-content {
    position: absolute;
    top: 100% !important;
    left: 0;
    z-index: 9999;
    max-height: none !important; /* Снимает ограничение по высоте */
    overflow-y: visible !important;  /* Делает контент видимым */
    transform: translateY(0) !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
}

.always-down .dropdown-heading {
    position: relative;
    z-index: 1000;
}

.always-down .dropdown-container .dropdown-heading::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

.always-down .dropdown-container .dropdown-content::-webkit-scrollbar {
    width: 5px;
}

.always-down .dropdown-container .dropdown-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.always-down .dropdown-container .dropdown-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

